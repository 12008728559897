<template>
  <div class="continer">
    <!-- 头部导航 -->
    <el-row>
      <el-col :span="20" :offset="2">
        <div class="grid-content bg-purple">
          <div style="height: 50px;max-height: 50px;position: relative;width: 60%;float: left;">
            <a href="https://dhldanhao.com" style="color: #4d4d4d;">
              <img src="../images/icon.png" class="icons" />
              <span
                style="line-height: 50px;position: absolute;left: 50px;font-size: 1.2em;font-weight: 600;">跨境电商物流单号网</span>
            </a>
          </div>
          <div class="cxdd">
            <!-- <el-button type="primary" round @click="dialog_login = true">登录</el-button>
            <el-button type="primary" round @click="dialog_reg = true">注册</el-button> -->
            <el-button type="danger" icon="el-icon-star-on" round @click="addFavorite()">谨防丢失，收藏本站</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--公告 -->
    <el-row>
      <el-col :span="20" :offset="2">
        <div class="grid-content bg-purple">
          <div
            style="background-color: rgba(255, 255, 255, 0.9); padding: 20px; margin-top: -18px; box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.12) 0px 0px 6px 0px;border-radius: 10px;">
            <div style="font-size:1.4em;font-weight: 800;"><i class="el-icon-message-solid"
                style="color: #F06025;"></i>&nbsp;平台介绍</div>
            <el-divider></el-divider>
            <p style="font-size: 14pt;margin-bottom: 8px;">国际DHL单号购买？国际USPS单号购买？国际EMS单号购买？国际E邮宝单号购买？国际FEDEX单号购买？
              不必再费时费力！
              欢迎来到我们的网站，这里提供着无与伦比的价格和卓越的技术，让你的物流追踪体验变得轻松而愉快。
            </p>
            <p style="font-size: 14pt;margin-bottom: 8px;">国际DHL单号购买？国际USPS单号购买？
              国际快递单号网，跨境测评单号，跨境物流单号，专为跨境电商测评卖家服务，www.dhldanhao.com。
            </p>
            <p style="font-size: 14pt;margin-bottom: 8px;">
              每日数十万的国际物流单号数据更新，确保你始终掌握最新的物流信息。
            </p>
            <p style="font-size: 14pt;margin-bottom: 8px;">
              我们提供全网最低廉的价格，不再区分搜索方式。一步到位，诚意满满，为你节省成本的同时提供卓越的服务。
            </p>
            <p style="font-size: 14pt;margin-bottom: 8px;">
              本站提供：DHL单号，USPS单号，EMS单号，EUB单号等。平台单号可用于：速卖通 敦煌网 eBay Amazon等跨境电商平台。单号均为官方真实单号，当天上网，可通过官方物流查询通道查询轨迹信息。
            </p>
            <p style="font-size: 14pt;">
              查询地址:
              <a style="color: #3598db; text-decoration: underline;" title="DHL官方批量查询平台"
                href="https://mydhlplus.dhl.com/cn/zh/tracking.html#/track-by-number" target="_blank"
                rel="noopener">DHL官方查询平台</a>&nbsp;&nbsp;
              <a style="color: #3598db; text-decoration: underline;" title="DHL官方批量查询平台" href="https://www.usps.com/"
                target="_blank" rel="noopener">USPS官方查询平台</a>&nbsp;&nbsp;
              <a style="color: #3598db; text-decoration: underline;" title="DHL官方批量查询平台"
                href="https://www.17track.net/zh-cn" target="_blank" rel="noopener">17TRACK全球物流查询平台</a>
            </p>
          </div>

          <br />
          <p
            style="font-size: 14pt;line-height: 30px;background-color: #FFE500;padding: 20px 15px;border-radius: 10px;box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.12) 0px 0px 6px 0px;">
            <span style="font-size: 16pt;color: red;"><i class="el-icon-warning"></i>温馨提示</span>
            <br />
            <span>1,本站单号只卖1次，单号售出后，系统会自动从售卖大厅下架该单号，不会重复卖，请放心购买并使用。</span>
            <br />
            <span>2,遇到非人为可控原因造成的不可用单号，不予补偿，没有售后，没有任何上网信息或已被使用的单号可联系客服更换。</span>
            <br />
            <span>3,本网站单号只限用于物流时效参考，请勿用作诈骗等违法行为，一经发现单号用作诈骗等违法行为，本站有权关闭其账号。</span>
            <br />
            <span>4,为避免恶意注册，现对新开账号收取10元/个的开户费，开户费会一并记入钱包余额。</span>
            <br />
            <span>5,当天的单号，2天内更新轨迹，周末除外。2天后没有更新轨迹的可以找客服换。</span>
            <br />
            <span>6,我们的单号 一般情况下都是 中午12点 - 23点之间陆续更新（USPS要3点才会更新），都是真实的单号 放心使用。</span>
            <br />
            <span>7,物流时效:Fedex：3-5天USPS：2-6天，DHL：2-7天，EMS：2-15天，E邮宝：10-25天（大多数国家）。</span>
          </p>
          <p
            style="font-size: 14pt;line-height: 30px;background-color: #2DC26B;padding: 20px 15px;border-radius: 10px; margin-top: 20px;margin-bottom: 15px;box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.12) 0px 0px 6px 0px;">
            <span style="font-size: 16pt;color: yellow;"><i class="el-icon-info"></i>促销活动</span>
            <br />
            <span style="color: #ffffff;">1,活动期间，推荐好友注册账户，赠送好友首次充值金的10%，被推荐好友开户之前，推荐人需先联系客服，否则无法享受活动！。</span>
            <br />
            <span style="color: #ffffff;width: 100%;">
              <span>2,充值活动</span>
              <p><img src="../images/ht.png" width="30" style="margin-right: 10px;" /><span
                  style="background-color: red;">单次充值 100元 赠送 10元！</span><span
                  style="cursor: pointer;color: yellow;margin-left: 20px;text-decoration: underline;"
                  @click="dialog_cz = true,je_1 = '100'">>>>立即充值</span></p>
              <br>
              <p><img src="../images/ht.png" width="30" style="margin-right: 10px;" /><span
                  style="background-color: red;">单次充值 300元 赠送 30元！</span><span
                  style="cursor: pointer;color: yellow;margin-left: 20px;text-decoration: underline;"
                  @click="dialog_cz = true,je_1 = '300'">>>>立即充值</span></p>
              <br>
              <p><img src="../images/ht.png" width="30" style="margin-right: 10px;" /><span
                  style="background-color: red;">单次充值 500元 赠送 70元！</span><span
                  style="cursor: pointer;color: yellow;margin-left: 20px;text-decoration: underline;"
                  @click="dialog_cz = true,je_1 = '500'">>>>立即充值</span></p>
              <br>
              <p><img src="../images/ht.png" width="30" style="margin-right: 10px;" /><span
                  style="background-color: red;">单次充值 1000元 赠送 200元！</span><span
                  style="cursor: pointer;color: yellow;margin-left: 20px;text-decoration: underline;"
                  @click="dialog_cz = true,je_1 = '1000'">>>>立即充值</span></p>
            </span>

          </p>
        </div>
      </el-col>
    </el-row>
    <!-- 表格 -->
    <br />
    <br />
    <!-- 表格购买 -->
    <el-row>
      <el-col :span="20" :offset="2">
        <div class="grid-content bg-purple" style="padding: 20px;margin-top: -18px;padding-top: 60px;">
          <div v-if="isLogin == false" class="xts" style="padding-left: 80px;"><img class="tb_img"
              src="../images/wallte.png" />亲，您尚未登录，<span
              style="color:yellow;cursor: pointer;text-decoration: underline;" @click="dialog_login = true">点击登录账户，即可购买
              ></span>
          </div>
          <div class="xts" style="padding-left: 80px;" v-if="isLogin == true">
            <img class="tb_img" src="../images/wallte.png" />
            欢迎您：{{ names }}，账户余额：<span>{{ money }}</span>
            <span style="cursor: pointer;color: red;margin-left: 20px;text-decoration: underline;"
              @click="dialog_cz = true">账户充值</span>
            <span style="color:yellow;cursor: pointer;margin-left: 20px;text-decoration: underline;"
              @click="loginOut()">退出账户</span>

          </div>
          <el-tabs v-model="activeName" type="border-card" @tab-click="handleTabClick">
            <el-tab-pane name="second">
              <span slot="label"><i class="el-icon-office-building"></i> 匹配城市</span>
              <div class="gj_form">
                <el-form ref="form" :model="form_cs" :inline="true">
                  <el-form-item label="发货地国家">
                    <el-select v-model="value_ct" filterable placeholder="发货地国家" clearable>
                      <el-option v-for="item in options_ct" :key="item.value" :label="item.label" :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.tag }}</span>
                      </el-option>
                    </el-select>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="发货地州">
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content">参考下排结果“发货地”模糊搜索</div>
                      <el-input v-model="form_cs.fhd_state" placeholder="参考结果输入（可模糊搜索）" clearable>
                      </el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item label="发货地城市">
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content">参考下排结果“发货地”模糊搜索</div>
                      <el-input v-model="form_cs.fhd_city" placeholder="参考结果输入（可模糊搜索）" clearable>
                      </el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item label="上网时间">
                    <el-date-picker v-model="form_cs.startTime" type="datetime" placeholder="开始时间"
                      default-time="00:00:00" style="width: 210px;" value-format="timestamp">
                    </el-date-picker>
                    <span style="display: inline-block;width: 40px;text-align: center;">至</span>
                    <el-date-picker v-model="form_cs.endTime" type="datetime" placeholder="结束时间" default-time="00:00:00"
                      style="width: 210px;" value-format="timestamp">
                    </el-date-picker>
                  </el-form-item>
                  <br />
                  <el-form-item label="收货地国家">
                    <el-select v-model="value_ct_sh" filterable placeholder="收货地国家" clearable>
                      <el-option v-for="item in options_ct_sh" :key="item.value" :label="item.label"
                        :value="item.value">
                        <span style="float: left">{{ item.label }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.tag }}</span>
                      </el-option>
                    </el-select>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="收货地州">
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">参考下排结果“收货地”模糊搜索</div>
                      <el-input v-model="form_cs.shd_state" placeholder="参考结果输入（可模糊搜索）" clearable>
                      </el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item label="收货地城市">
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">参考下排结果“收货地”模糊搜索</div>
                      <el-input v-model="form_cs.shd_city" placeholder="参考结果输入（可模糊搜索）" clearable>
                      </el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item label="单号类型">
                    <el-select v-model="value_cs_lx" placeholder="请选择">
                      <el-option v-for="item in Tpoptions" :key="item.value" :label="item.label" :value="item.value"
                        :disabled="item.disabled">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="单号状态">
                    <el-select v-model="value_status" placeholder="请选择">
                      <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="每页显示数量">
                    <el-input v-model="form_cs.pagesize" placeholder="默认:50,最大500" clearable style="width: 160px;">
                    </el-input>
                  </el-form-item>

                  <el-form-item label="">
                    <el-button type="warning" icon="el-icon-delete" @click="claer_ct()" round>一键清空</el-button>
                    <el-button type="success" icon="el-icon-shopping-cart-2" round @click="batch_buy_ct()">{{ ct_plgm }}
                    </el-button>
                    <el-button type="primary" icon="el-icon-search" @click="getCity('rf','pages')" round>搜索</el-button>
                  </el-form-item>
                </el-form>
                <br />

              </div>
              <el-table :header-cell-style="{background:'#EBF3FF',color:'#3D76CE'}" border
                @selection-change="handleSelectionChange_ct" :data="ct_data" style="width: 100%" size="small">
                <el-table-column type="selection" width="35">
                </el-table-column>
                <el-table-column label="id" prop="id" v-if="false">
                </el-table-column>
                <el-table-column label="单号" prop="waybill_sn">
                </el-table-column>
                <el-table-column label="发货地" prop="start_address">
                </el-table-column>
                <el-table-column label="收货地">
                  <template slot-scope="scope">
                    <span v-if="scope.row.types =='USPS'">USA,{{ scope.row.end_city }},{{ scope.row.end_state }}</span>
                    <span v-else-if="scope.row.types =='EUB'">{{ scope.row.end_country }}</span>
                    <span v-else-if="scope.row.types =='EMS'">{{ scope.row.end_country }}</span>
                    <span v-else>{{ scope.row.end_address }}</span>
                  </template>
                </el-table-column>
                </el-table-column>
                <el-table-column label="价格" prop="price" width="50">
                </el-table-column>
                <el-table-column label="单号类型" prop="types" width="70">
                </el-table-column>
                <el-table-column label="单号状态" width="95">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.sn_status =='1'" type="success" effect="dark">运输中</el-tag>
                    <el-tag v-else type="warning" effect="dark">已上网</el-tag>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="适用平台">
                  <el-tag effect="dark">敦煌网,速卖通,亚马逊,eBay,独立站等跨境平台</el-tag>
                </el-table-column> -->
                <el-table-column label="上网时间" prop="online_time" width="140">
                </el-table-column>
                <el-table-column label="预计送达时间" prop="expected" width="150">
                </el-table-column>
                <el-table-column label="操作" width="120">
                  <template slot-scope="scope">
                    <el-button type="success" @click="dialogct_buy(scope.row)">购买单号</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination align='center' @size-change="handleSizeChange_ct" @current-change="handleCurrentChange_ct"
                :current-page.sync="currentPage_ct" :page-size="pagesize_ct" layout="total,prev, pager, next, jumper"
                :total="total_ct" style="margin-top: 20px;">
              </el-pagination>
            </el-tab-pane>
            <el-tab-pane name="third">
              <span slot="label"><i class="el-icon-eleme"></i> 匹配邮编</span>
              <div class="gj_form">
                <el-form ref="form" :model="codes_form" :inline="true">
                  <el-form-item label="发货地地址">
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content">示例一（州）：CA<br />示例二（城市）：SEBASTOPOL<br />示例三（邮编）：95472</div>
                      <el-input v-model="codes_form.adr" placeholder="参考结果输入(可模糊搜索)" clearable style="width: 180px;">
                      </el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item label="单号状态">
                    <el-select v-model="value_status" placeholder="请选择">
                      <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="单号类型">
                    <el-select v-model="codes_types" placeholder="请选择">
                      <el-option v-for="item in sn_types" :key="item.value" :label="item.label" :value="item.value"
                        :disabled="item.disabled">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="上网时间">
                    <el-date-picker v-model="codes_form.startTime" type="datetime" placeholder="开始时间"
                      default-time="00:00:00" style="width: 210px;" value-format="timestamp">
                    </el-date-picker>
                    <span style="display: inline-block;width: 40px;text-align: center;">至</span>
                    <el-date-picker v-model="codes_form.endTime" type="datetime" placeholder="结束时间"
                      default-time="00:00:00" style="width: 210px;" value-format="timestamp">
                    </el-date-picker>
                  </el-form-item>
                  <el-input type="textarea" :rows="10" placeholder="请输入收货地邮编,支持批量查询,一行一个邮编.
例子:
00001
00002
00003
00004
00005
00006
..." v-model="codes_form.codes">
                  </el-input>

                  <el-button type="warning" icon="el-icon-delete" @click="clear_cd()" round
                    style="margin-top: 20px;">一键清空</el-button>
                  <el-button type="success" icon="el-icon-shopping-cart-2" round @click="batch_buy_gj()">{{ plgm_gj }}
                  </el-button>
                  <el-form-item label="">
                    <el-button type="primary" icon="el-icon-search" @click="getCodes()" round
                      style="margin-left: 20px;margin-top: 20px;">搜索</el-button>
                  </el-form-item>
                  <br />
                </el-form>
              </div>
              <el-table :header-cell-style="{background:'#EBF3FF',color:'#3D76CE'}" border
                @selection-change="handleSelectionChange" :data="tableData" style="width: 100%" size="small">
                <el-table-column type="selection" width="35">
                </el-table-column>
                <el-table-column label="id" prop="id" v-if="false">
                </el-table-column>
                <el-table-column label="单号" prop="waybill_sn" width="190">
                </el-table-column>
                <el-table-column label="发货地" prop="start_address">
                </el-table-column>
                <el-table-column label="收货地" prop="end_country">
                </el-table-column>
                <el-table-column label="收货地邮编号" prop="codes">
                </el-table-column>
                <el-table-column label="价格" prop="price" width="50">
                </el-table-column>
                <el-table-column label="单号类别" prop="types" width="70">
                </el-table-column>
                <el-table-column label="单号状态" width="80">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.sn_status =='1'" type="success" effect="dark">运输中</el-tag>
                    <el-tag v-else type="warning" effect="dark">已上网</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="上网时间" prop="online_time" width="140">
                </el-table-column>
                <el-table-column label="预计送达时间" prop="expected" width="150">
                </el-table-column>
                <el-table-column label="操作" width="120">
                  <template slot-scope="scope">
                    <el-button type="success"
                      @click="dialog_gj_buy = true,gj_buy_types='dan',order_list='',gj_buy_id=scope.row.id">购买单号
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="pagesize" layout="total,prev, pager, next, jumper"
                :total="total" style="margin-top: 20px;">
              </el-pagination>
            </el-tab-pane>
            <el-tab-pane name="myOrder">
              <span slot="label"><i class="el-icon-goods"></i>已购单号</span>
              <el-table :header-cell-style="{background:'#EBF3FF',color:'#3D76CE'}"
                @selection-change="handleSelectionChange_my" :data="my_data" style="width: 100%" size="small"
                bordersize="small" border="">
                <!-- <el-table-column type="selection" width="35">
                </el-table-column> -->
                <el-table-column label="id" prop="id" v-if="false">
                </el-table-column>
                <el-table-column label="单号" prop="waybill_sn">
                </el-table-column>
                <el-table-column label="发货地" prop="start_address">
                </el-table-column>
                <el-table-column label="收货地" prop="end_country">
                  <template slot-scope="scope">
                    <span v-if="scope.row.types =='USPS'" type="success"
                      effect="dark">{{ scope.row.end_country }}</span>
                    <span v-else>{{ scope.row.end_address }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="邮编号" prop="codes" width="80">
                </el-table-column>
                <el-table-column label="价格" prop="price" width="50">
                </el-table-column>
                <el-table-column label="单号状态" width="80">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.sn_status =='1'" type="success" effect="dark">运输中</el-tag>
                    <el-tag v-else type="warning" effect="dark">已上网</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="上网时间" prop="online_time" width="150">
                </el-table-column>
                <el-table-column label="预计送达时间" prop="expected" width="150">
                </el-table-column>
                <el-table-column label="购买时间" prop="updatetime" width="150">
                </el-table-column>
              </el-table>
              <el-pagination align='center' @size-change="handleSizeChange_my" @current-change="handleCurrentChange_my"
                :current-page.sync="currentPage_my" :page-size="100" layout="prev, pager, next, jumper"
                :total="total_my" style="margin-top: 20px;">
              </el-pagination>
            </el-tab-pane>
            <el-tab-pane name="kj">
              <span slot="label"><i class="el-icon-phone-outline"></i> 跨境服务</span>
              <div style="text-align: center;">
                <p style="font-size: 40px;font-weight: 600;color: red;">跨境自动化软件</p>
                <br />
                <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left">
                  ○敦煌网自动注册买家号软件（1个ip可注册5个账号）。</p>
                <br />
                <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left">
                  ○敦煌网谷歌企业邮箱自动注册买家号软件（1个IP可无限制注册，稳定）。</p>
                <br />
                <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left">○敦煌网多SKU自动加购软件（多账号自动加购）。
                </p>
                <br />
                <br />
                <p style="font-size: 40px;font-weight: 600;color: red;">跨境测评服务</p>
                <br />
                <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left">
                  ○敦煌网、速卖通、lazada、虾皮、TikTok测评服务，详情请咨询客服。</p>
                <br />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <!-- 在线客服 -->
    <aside class="customer-contact-channel">
      <div class="channel-item channel-item-GZH" id="gzh"><img src="../images/wxkf.png" alt="">
        <p data-font="dinRegular" class="text">微信客服</p>
        <div class="hover-item" id="gzh-item"><img src="../images/wx01.png" alt=""></div>
      </div>
      <div class="channel-item channel-item-GZH" id="qq_1"><img src="../images/qqkf.png" alt="">
        <p data-font="dinRegular" class="text">QQ客服</p>
        <div class="hover-item" id="qq-1-item"><img src="../images/qq.png" alt=""></div>
      </div>
    </aside>
    <!-- 底部 -->
    <el-footer>
      <div style="text-align: center;margin-top: 40px;">
        版权所有 Copyright(C)2023 <a href="https://www.dhldanhao.com" style="color: #000000;">www.dhldanhao.com</a>
      </div>
    </el-footer>
    <!--登录 -->
    <el-dialog :visible.sync="dialog_login" width="30%" title="账号登录">
      <el-form :rules="loginFormRules" :model="form_login" class="zh_form" ref="form_loginFormRef">
        <el-form-item prop="user">
          <el-input v-model="form_login.user" placeholder="请输入邮箱，姓名或手机号码登录" clearable>
            <template slot="prepend"><span style="color: red;">*</span><i class="el-icon-user-solid"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input v-model="form_login.pass" type="password" placeholder="请输入密码" clearable>
            <template slot="prepend"><span style="color: red;">*</span><i
                class="el-input__icon iconfont icon-3702mima"></i></template>
          </el-input>
        </el-form-item>
        <div style="width: 100%;">
          <el-button type="primary" style="width: 100%;font-size: 20px" @click="login()">登录账号</el-button>
        </div>
        <div style="width: 100%;margin-top: 30px;">
          <div @click="dialog_login=false,dialog_reg = true,getCodesy()"
            style="float: left;color: rgb(53, 152, 219);cursor: pointer;text-decoration:underline;font-size: 18px;">
            没有账号？注册账号</div>
          <div @click="dialog_sd=true"
            style="float: right;color: red;cursor: pointer;text-decoration:underline;font-size: 18px;">忘记密码？请联系在线客服。
          </div>
          <div style="clear: both;"></div>
        </div>
      </el-form>
    </el-dialog>
    <!-- 注册 -->
    <el-dialog :visible.sync="dialog_reg" width="30%" title="账号注册">
      <el-form :model="form_reg" :rules="regFormRules" ref="form_regFormRef" class="zh_form">
        <el-form-item prop="user">
          <el-input v-model="form_reg.user" placeholder="请输入邮箱,(后期找回密码客服会核实)" clearable>
            <template slot="prepend"><span style="color: red;">*</span><i class="el-icon-platform-eleme"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input v-model="form_reg.pass" placeholder="请输入密码" clearable type="password" show-password>
            <template slot="prepend"><span style="color: red;">*</span><i
                class="el-input__icon iconfont icon-3702mima"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="rpass">
          <el-input v-model="form_reg.rpass" placeholder="再次输入密码" clearable type="password" show-password>
            <template slot="prepend"><span style="color: red;">*</span><i
                class="el-input__icon iconfont icon-3702mima"></i></template>
          </el-input>
        </el-form-item>
        <el-divider>完善信息</el-divider>
        <el-form-item prop="xm">
          <el-input v-model="form_reg.xm" placeholder="请输入真实姓名,(后期找回密码客服会核实)" clearable>
            <template slot="prepend"><span style="color: red;">*</span><i class="el-icon-user-solid"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="form_reg.phone" placeholder="请输入手机号码,(后期找回密码客服会核实)" clearable>
            <template slot="prepend"><span style="color: red;">*</span><i class="el-icon-phone"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="codes">
          <div style="display: inline-block;width: 74%;">
            <el-input v-model="form_reg.codes" placeholder="验证码" clearable>
              <template slot="prepend"><span style="color: red;">*</span><i class="el-icon-picture"></i></template>
            </el-input>
          </div>
          <div style="display: inline-block;width: 20%;">
            <img :src="coadeId"
              style="right:-4px;width: 25%;position: absolute;top: -1px;border-radius: 5px;border: 2px solid #eee;height: 53px;"
              @click='getCodesy()' alt='请点击刷新.' />
          </div>
        </el-form-item>
        <!-- <p style="color: red;font-size: 18px;">暂时停用自主注册，请联系客服开通账号</p>
        <br /> -->
        <div style="width: 100%;">
          <el-button type="primary" style="width: 100%;font-size: 20px;" @click="reg()">注册账号</el-button>
        </div>
        <div style="width: 100%;margin-top: 30px;">
          <div @click="dialog_reg = false,dialog_login=true"
            style="float: right;color: rgb(53, 152, 219);cursor: pointer;text-decoration:underline;font-size: 18px;">
            已有账号？立即登录<i class="el-icon-arrow-right"></i></div>
          <div style="clear: both;"></div>
        </div>
      </el-form>
    </el-dialog>
    <!--购买确认提示国家 -->
    <el-dialog :visible.sync="dialog_gj_buy" width="30%">
      <svg t="1696952433150" class="icon xsq" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
        p-id="4032" width="200" height="200">
        <path
          d="M512 0a512 512 0 1 0 0 1024A512 512 0 0 0 512 0z m51.2 819.2H460.8V358.4h102.4v460.8z m-51.2-512a51.2 51.2 0 1 1 0-102.4 51.2 51.2 0 0 1 0 102.4z"
          fill="#FFAD0D" p-id="4033"></path>
      </svg><span style="font-size: 22px;">是否要购买此单号？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_gj_buy = false">取 消</el-button>
        <el-button type="primary" v-if="gj_buy_types == 'dan'" @click="gj_buy()">确 定</el-button>
        <el-button type="primary" v-if="gj_buy_types == 'batch'" @click="gj_buy_batch()">确 定</el-button>
      </span>
    </el-dialog>
    <!--购买确认提示城市 -->
    <el-dialog :visible.sync="dialog_ct_buy" width="30%">
      <svg t="1696952433150" class="icon xsq" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
        p-id="4032" width="200" height="200">
        <path
          d="M512 0a512 512 0 1 0 0 1024A512 512 0 0 0 512 0z m51.2 819.2H460.8V358.4h102.4v460.8z m-51.2-512a51.2 51.2 0 1 1 0-102.4 51.2 51.2 0 0 1 0 102.4z"
          fill="#FFAD0D" p-id="4033"></path>
      </svg><span style="font-size: 22px;">是否要购买此单号？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_ct_buy = false">取 消</el-button>
        <el-button type="primary" v-if="ct_buy_types=='dan'" @click="ct_buy()">确 定</el-button>
        <el-button type="primary" v-if="ct_buy_types=='batch'" @click="ct_buy_batch()">确 定</el-button>
      </span>
    </el-dialog>
    <!--购买成功提示框 -->
    <el-dialog :visible.sync="dialog_buy_cg" width="25%" custom-class="dialog_buy">
      <img src="../images/success.png" class="buy_cg" />
      <p style="font-size: 24px;text-align: center;margin-left: 20px;">购买成功！</p>
      <p style="font-size: 14px;text-align: center;margin-top: 5px;color: #8993A2;">订单号：{{ buy_order_sn }}</p>
      <br />
      <div
        style="height: 1px;background-image: linear-gradient(to right, #888888 0%, #848484 50%, transparent 0%);background-size: 14px 1px;background-repeat: repeat-x;">
      </div>
      <br />
      <div style="width: 50%;display: inline-block;">
        <p style="font-size: 16px;color: #8993A2;margin-bottom: 5px;">购买日期</p>
        <p style="font-size: 22px;color: #3e3e3e;">{{ buy_times }}</p>
      </div>
      <div style="width: 50%;display: inline-block;text-align: right;">
        <p style="font-size: 16px;color: #8993A2;margin-bottom: 5px;">购买数量</p>
        <p style="font-size: 22px;">{{ buy_sums }}</p>
      </div>
      <br />
      <br />
      <br />
      <div style="width: 50%;display: inline-block;">
        <p style="font-size: 16px;color: #8993A2;margin-bottom: 5px;">购买状态</p>
        <el-tag type="success">成功</el-tag>
      </div>
      <div style="width: 50%;display: inline-block;text-align: right;">
        <p style="font-size: 16px;color: #8993A2;margin-bottom: 5px;">支付价格</p>
        <el-tag type="success">{{ buy_money }}</el-tag>
      </div>
      <br />
      <br />
      <div
        style="height: 1px;background-image: linear-gradient(to right, #888888 0%, #848484 50%, transparent 0%);background-size: 14px 1px;background-repeat: repeat-x;">
      </div>
      <br />
      <div style="font-size: 24px;font-weight: 600;text-align: center;">单号列表</div>
      <div style="margin-top: 0px;">
        <br />
        <textarea class="order-info" rows="10">{{ order_list }}</textarea>
        <el-button style="width: 100%;margin-top: 20px;font-size: 22px;" type="primary" round @click="copy()">一键复制单号
        </el-button>
      </div>
    </el-dialog>
    <!-- 充值提示框 -->
    <el-dialog :visible.sync="dialog_cz" width="500px" custom-class="dialog_buy">
      <div
        style="text-align: center;font-size: 22px;font-weight: 600;position: absolute;left: 0;right: 0;width: 100%;top: 40px;">
        在线充值</div>
      <div style="margin-top: 50px;">
        <!-- <p style="font-size: 20px;text-align: center;color: red;">维护升级中，充值请找客服。</p> -->
        <span style="font-size: 18px;">充值类型：</span>
        <el-radio v-model="cz_type" label="2" border>
          <img src="../images/wxzf.png" height="20" />
          <span class="rdzt">微信</span>
        </el-radio>
        <el-radio v-model="cz_type" label="1" border disabled>
          <img src="../images/zfb.png" height="20" />
          <span class="rdzt">支付宝</span>
        </el-radio>
        </el-radio>
      </div>
      <div style="margin-top: 20px;">
        <span style="font-size: 18px;">充值金额：</span>
        <el-radio v-if="money == 0" v-model="je_1" label="10" border>
          <span class="rdzt">10元</span>
        </el-radio>
        <el-radio v-if="money > 0" disabled v-model="je_1" label="10" border>
          <span class="rdzt">10元</span>
        </el-radio>
        <el-radio v-model="je_1" label="50" border style="margin-bottom: 20px;">
          <span class="rdzt">50元</span>
        </el-radio>
        <span style="font-size: 18px;opacity: 0;">充值金近额：</span>
        <el-radio v-model="je_1" label="100" border>
          <img src="../images/hot.png" height="20" />
          <span class="rdzt">100元</span>
          <div class="zsts">
            <img src="../images/zs.png" height="20" />
            赠 10元
          </div>
        </el-radio>
        <el-radio v-model="je_1" label="300" border style="margin-bottom: 20px;">
          <span class="rdzt">300元</span>
          <div class="zsts">
            <img src="../images/zs.png" height="20" />
            赠 30元
          </div>
        </el-radio>
        <span style="font-size: 18px;opacity: 0;">充值金近额：</span>
        <el-radio v-model="je_1" label="500" border>
          <span class="rdzt">500元</span>
          <div class="zsts">
            <img src="../images/zs.png" height="20" />
            赠 70元
          </div>
        </el-radio>
        <el-radio v-model="je_1" label="1000" border>
          <span class="rdzt">1000元</span>
          <div class="zsts">
            <img src="../images/zs.png" height="20" />
            赠 200元
          </div>
        </el-radio>
        <div style="width: 100%;text-align: center;margin-top: 40px;">
          <el-button type="primary" round style="width: 40%;font-size: 20px;" @click="cz()">确认支付</el-button>
        </div>
      </div>
      <div>
      </div>
    </el-dialog>
    <!-- 二维码支付 -->
    <el-dialog :visible.sync="dialog_ewm" width="38%" custom-class="dialog_buy" @close="closeTime()">
      <div style="text-align: center;border: 1px solid #ddd;margin-bottom: 20px;">
        <img src="../images/weixin.jpg" width="150" />
      </div>
      <div
        style="background-color: yellow;color:red;padding: 5px 0;font-size: 22px;text-align: center;font-weight: 600;">
        为了您正常支付 请务必付款 <span style="color: #000000;font-size: 28px;">{{ reallyPrice }}</span>
        元，备注说明无需填写，否则订单无法完成！如有疑问请联系客服。</div>
      <br />
      <br />
      <div
        style="height: 1px;background-image: linear-gradient(to right, #888888 0%, #848484 50%, transparent 0%);background-size: 14px 1px;background-repeat: repeat-x;">
      </div>
      <br />
      <br />
      <div style="width: 100%;text-align: center;">
        <img src="../images/scan.png" width="300px" style="display: inline-block;" />
      </div>
      <div style="font-size: 28px;font-weight: 600;text-align: center;margin: 20px 0;color: black;"> ￥ {{ reallyPrice }}
      </div>
      <div style="text-align: center;">
        <div class="qr-code" ref="qrcodejs2" style="margin: 0 auto;text-align: center;"></div>
      </div>
      <br />
      <br />
      <div
        style="height: 1px;background-image: linear-gradient(to right, #888888 0%, #848484 50%, transparent 0%);background-size: 14px 1px;background-repeat: repeat-x;">
      </div>
      <br />
      <br />
      <div style="font-size: 16px;color: #000000;line-height: 25px;">
        <span>金额:</span><span style="float: right;">{{ price }}</span>
        <br />
        <span>商户订单:</span><span style="float: right;">{{ payId }}</span>
        <br />
        <span>创建时间:</span><span style="float: right;">{{ datas }}</span>
        <br />
        <span>状态:</span><span style="float: right;">{{ state }}</span>
      </div>
      <br />
      <br />
      <div
        style="height: 1px;background-image: linear-gradient(to right, #888888 0%, #848484 50%, transparent 0%);background-size: 14px 1px;background-repeat: repeat-x;">
      </div>
      <br />
      <br />
      <div style="font-size: 12px;color: #8492a6;text-align: center;line-height: 25px;">
        <p>手机用户可保存上方二维码到手机中</p>
        <p>在微信扫一扫中选择“相册”即可</p>
      </div>
    </el-dialog>
    <!-- 弹窗公告 -->
    <el-dialog :visible.sync="dialog_sd" width="60%" custom-class="dialog_buy" style="text-align: center;">
      <div style="width: 90%;margin: 0 auto;">
        <p style="font-size: 40px;font-weight: 600;color: red;">平台公告</p>
        <br>
        <br>
        <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left;">
          ○关于敦煌网无法回填线下提供以下解决方案。
          <br />找客服代开海外仓，需要收费，成功之后可以继续使用以前的海外DHL USPS。
          （由于海外仓是找的海外物流商合作，物流商会收取相应的材料费用，请悉知！）
        </p>
        <br>
        <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left;">
          ○亚马逊、阿里国际站、temu欧代注册，未关联欧盟负责人或影响销售，我们提供高效、精准的欧代注册服务，助您的商品顺利通行欧洲！！！
        </p>
        <br>
        <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left;">
          ○出售谷歌邮箱，Hotmail邮箱，yahoo邮箱，推特邮箱账号，脸书邮箱账号。
        </p>
        <br>
        <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left">
          ○因部分地区缓存刷新问题造成内部服务器错误无法访问，请按照下面方法修改为阿里DNS即可：<a style="color: #3598db; text-decoration: underline;"
            title="https://alidns.com/knowledge?type=SETTING_DOCS#user"
            href="https://alidns.com/knowledge?type=SETTING_DOCS#user" target="_blank"
            rel="noopener">点击查看如何修改为阿里DNS！</a>！</p>
        <br>
        <p style="color: forestgreen;font-size: 24px;font-weight: 500;text-align:left">
          ○平台备用地址：<a style="color: #3598db; text-decoration: underline;" title="https://by.byqgwcgzs.cn/"
            href="https://by.byqgwcgzs.cn/" target="_blank"
            rel="noopener">https://by.byqgwcgzs.cn/</a>（因为某些地区的问题导致无法访问，请使用备用地址访问。）</p>
        <br>
        <br />
        <p style="font-size: 40px;font-weight: 600;color: red;">客服微信</p>
        <br>
        <p style="color: #000000;font-size: 700;font-size: 18px;color: red;">(不能使用的单号请找客服免费更换)</p>
        <p style="color: #000000;font-size: 700;font-size: 18px;color: red;">需要EMS EUB FEDEX其他单号，请添加客服微信线下获取</p>
        <p>
          <img src="../images/wx01.png" alt="" width="400">
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  var orderid = "";
  import QRCode from 'qrcodejs2'
  import $ from 'jquery';
  import {
    Message
  } from 'element-ui'
  import {
    dataTool
  } from 'echarts';
  export default {
    data() {
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('两次密码输入不一致'))
        } else if (value !== this.form_reg.rpass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        codes_types: "USPS",
        sn_types: [{
          value: 'USPS',
          label: 'USPS'
        }],
        Tpoptions: [{
          value: 'DHL',
          label: 'DHL'
        },{
          value: 'USPS',
          label: 'USPS'
        }],
        statusOptions: [{
          value: '0',
          label: '已上网'
        }, {
          value: '1',
          label: '运输中'
        }],
        sp_dia: false,
        dialog_sd: true,
        value_cs_lx: "DHL",
        value_status: "0",
        codes_form: {
          startTime: "",
          endTime: "",
          codes: "",
          adr: ""
        },
        deadline4: "",
        price: "",
        state: "",
        timers: "",
        datas: "",
        payId: "",
        reallyPrice: "",
        qrcode: null,
        dialog_ewm: false,
        cz_type: "2",
        je_1: "50",
        cz_je: "",
        dialog_cz: false,
        buy_times: "",
        buy_money: "",
        buy_order_sn: "",
        buy_sums: "",
        token: "",
        //coadeId: "https://api.dhldanhao.com/index?s=captcha/",
        coadeId: "https://api.byqgwcgzs.cn/index?s=captcha/",
        isLogin: false,
        value_gj_lx: "DHL",
        order_list: "",
        dialog_buy_cg: false,
        options_ct: [{
          value: 'CHINA',
          label: '中国',
          tag: 'CHINA'
        }, {
          value: 'NOTCHINA',
          label: '非中国（外邮DHL）',
          tag: 'NOTCHINA'
        }, {
          value: 'USA',
          label: '美国',
          tag: 'USA'
        }, {
          value: 'UK',
          label: '英国',
          tag: 'UK'
        }, {
          value: 'AUSTRALIA',
          label: '澳大利亚',
          tag: 'AUSTRALIA'
        }, {
          value: 'Azerbaijan',
          label: '阿塞拜疆',
          tag: 'Azerbaijan'
        }, {
          value: 'AUSTRIA',
          label: '奥地利',
          tag: 'AUSTRIA'
        }, {
          value: 'Algeria',
          label: '阿尔及利亚',
          tag: 'Algeria'
        }, {
          value: 'Albania',
          label: '阿尔巴尼亚',
          tag: 'Albania'
        }, {
          value: 'Angola',
          label: '安哥拉',
          tag: 'Angola'
        }, {
          value: 'Andorra',
          label: '安道尔',
          tag: 'Andorra'
        }, {
          value: 'BAHRAIN',
          label: '巴林',
          tag: 'BAHRAIN'
        }, {
          value: 'Botswana',
          label: '博茨瓦纳',
          tag: 'Botswana'
        }, {
          value: 'burkina faso ',
          label: '布基纳法索',
          tag: 'burkina faso '
        }, {
          value: 'BANGLADESH',
          label: '孟加拉国',
          tag: 'BANGLADESH'
        }, {
          value: 'BELGIUM',
          label: '比利时',
          tag: 'BELGIUM'
        }, {
          value: 'SAN MARINO',
          label: '圣马力诺',
          tag: 'SAN MARINO'
        }, {
          value: 'Oman',
          label: '阿曼',
          tag: 'Oman'
        }, {
          value: 'Maldives',
          label: '马尔代夫',
          tag: 'Maldives'
        }, {
          value: 'Malawi',
          label: '马拉维',
          tag: 'Malawi'
        }, {
          value: 'Slovakia',
          label: '斯洛伐克',
          tag: 'Slovakia'
        }, {
          value: 'Cameroon',
          label: '喀麦隆',
          tag: 'Cameroon'
        }, {
          value: 'BOLIVIA',
          label: '玻利维亚',
          tag: 'BOLIVIA'
        }, {
          value: 'BRAZIL',
          label: '巴西',
          tag: 'BRAZIL'
        }, {
          value: 'BRUNEI',
          label: '文莱',
          tag: 'BRUNEI'
        }, {
          value: 'BULGARIA',
          label: '保加利亚',
          tag: 'BULGARIA'
        }, {
          value: 'Bosnia',
          label: '波斯尼亚',
          tag: 'Bosnia'
        }, {
          value: 'Ghana',
          label: '加纳',
          tag: 'Ghana'
        }, {
          value: 'CANADA',
          label: '加拿大',
          tag: 'CANADA'
        }, {
          value: 'CAYMAN ISLANDS',
          label: '开曼群岛',
          tag: 'CAYMAN ISLANDS'
        }, {
          value: 'NORTH MACEDONIA',
          label: '马其顿',
          tag: 'NORTH MACEDONIA'
        }, {
          value: 'CHILE',
          label: '智利',
          tag: 'CHILE'
        }, {
          value: 'JERSEY',
          label: '泽西岛',
          tag: 'JERSEY'
        }, {
          value: 'zimbabwe',
          label: '津巴布韦',
          tag: 'zimbabwe'
        }, {
          value: 'curacao ',
          label: '库拉索',
          tag: 'curacao '
        }, {
          value: 'CHINA MAINLAND',
          label: '中国大陆',
          tag: 'CHINA MAINLAND'
        }, {
          value: 'Colombia',
          label: '哥伦比亚',
          tag: 'Colombia'
        }, {
          value: 'Costa Rica',
          label: '哥斯达黎加',
          tag: 'Costa Rica'
        }, {
          value: 'EL SALVADOR',
          label: '萨尔瓦多',
          tag: 'EL SALVADOR'
        }, {
          value: 'COTE D IVOIRE',
          label: '科特迪瓦',
          tag: 'COTE D IVOIRE'
        }, {
          value: 'Kosovo',
          label: '科索沃',
          tag: 'Kosovo'
        }, {
          value: 'Ecuador',
          label: '厄瓜多尔',
          tag: 'Ecuador'
        }, {
          value: 'Yemen',
          label: '也门',
          tag: 'Yemen'
        }, {
          value: 'CROATIA',
          label: '克罗地亚',
          tag: 'CROATIA'
        }, {
          value: 'Rwanda',
          label: '卢旺达',
          tag: 'Rwanda'
        }, {
          value: 'CYPRUS',
          label: '塞浦路斯',
          tag: 'CYPRUS'
        }, {
          value: 'CZECH REPUBLIC, THE',
          label: '捷克共和国',
          tag: 'CZECH REPUBLIC, THE'
        }, {
          value: 'DENMARK',
          label: '丹麦',
          tag: 'DENMARK'
        }, {
          value: 'Reunion Island',
          label: '留尼汪岛',
          tag: 'Reunion Island'
        }, {
          value: 'DOMINICAN REPUBLIC',
          label: '多米尼加共和国',
          tag: 'DOMINICAN REPUBLIC'
        }, {
          value: 'Erdogua',
          label: '厄尔多瓜',
          tag: 'Erdogua'
        }, {
          value: 'Cambodia',
          label: '柬埔寨',
          tag: 'Cambodia'
        }, {
          value: 'Guinea',
          label: '几内亚',
          tag: 'Guinea'
        }, {
          value: 'EGYPT',
          label: '埃及',
          tag: 'EGYPT'
        }, {
          value: 'ESTONIA',
          label: '爱沙尼亚',
          tag: 'ESTONIA'
        }, {
          value: 'FINLAND',
          label: '芬兰',
          tag: 'FINLAND'
        }, {
          value: 'FRANCE',
          label: '法国',
          tag: 'FRANCE'
        }, {
          value: 'GERMANY',
          label: '德国',
          tag: 'GERMANY'
        }, {
          value: 'GREECE',
          label: '希腊',
          tag: 'GREECE'
        }, {
          value: 'GUAM',
          label: '关岛',
          tag: 'GUAM'
        }, {
          value: 'NAMIBIA',
          label: '纳米比亚',
          tag: 'NAMIBIA'
        }, {
          value: 'Georgia',
          label: '格鲁吉亚',
          tag: 'Georgia'
        }, {
          value: 'GUATEMALA',
          label: '危地马拉',
          tag: 'GUATEMALA'
        }, {
          value: 'HAITI',
          label: '海地',
          tag: 'HAITI'
        }, {
          value: 'HONG KONG SAR, CHINA',
          label: '中国香港特别行政区',
          tag: 'HONG KONG SAR, CHINA'
        }, {
          value: 'HUNGARY',
          label: '匈牙利',
          tag: 'HUNGARY'
        }, {
          value: 'ICELAND',
          label: '冰岛',
          tag: 'ICELAND'
        }, {
          value: 'Jamaica',
          label: '牙买加',
          tag: 'Jamaica'
        }, {
          value: 'INDIA',
          label: '印度',
          tag: 'INDIA'
        }, {
          value: 'INDONESIA',
          label: '印度尼西亚',
          tag: 'INDONESIA'
        }, {
          value: 'IRELAND, REPUBLIC OF',
          label: '爱尔兰共和国',
          tag: 'IRELAND, REPUBLIC OF'
        }, {
          value: 'MARTINIQUE',
          label: '马提尼克岛',
          tag: 'MARTINIQUE'
        }, {
          value: 'ISRAEL',
          label: '以色列',
          tag: 'ISRAEL'
        }, {
          value: 'ITALY',
          label: '意大利',
          tag: 'ITALY'
        }, {
          value: 'IRAN (ISLAMIC REPUBLIC OF)',
          label: '伊朗',
          tag: 'IRAN (ISLAMIC REPUBLIC OF)'
        }, {
          value: 'JAPAN',
          label: '日本',
          tag: 'JAPAN'
        }, {
          value: 'JORDAN',
          label: '约旦',
          tag: 'JORDAN'
        }, {
          value: 'KAZAKHSTAN',
          label: '哈萨克斯坦',
          tag: 'KAZAKHSTAN'
        }, {
          value: 'KENYA',
          label: '肯尼亚',
          tag: 'KENYA'
        }, {
          value: 'KOREA, REPUBLIC OF (SOUTH K.)',
          label: '韩国',
          tag: 'South Korea'
        }, {
          value: 'KUWAIT',
          label: '科威特',
          tag: 'KUWAIT'
        }, {
          value: 'LATVIA',
          label: '拉脱维亚',
          tag: 'LATVIA'
        }, {
          value: 'LITHUANIA',
          label: '立陶宛',
          tag: 'LITHUANIA'
        }, {
          value: 'Liberia',
          label: '利比里亚',
          tag: 'Liberia'
        }, {
          value: 'LUXEMBOURG',
          label: '卢森堡',
          tag: 'LUXEMBOURG'
        }, {
          value: 'MACAO SAR, CHINA',
          label: '中国澳门',
          tag: 'MACAO SAR, CHINA'
        }, {
          value: 'MALAYSIA',
          label: '马来西亚',
          tag: 'MALAYSIA'
        }, {
          value: 'MALTA',
          label: '马耳他',
          tag: 'MALTA'
        }, {
          value: 'MAURITIUS',
          label: '毛里求斯',
          tag: 'MAURITIUS'
        }, {
          value: 'MEXICO',
          label: '墨西哥',
          tag: 'MEXICO'
        }, {
          value: 'Lebanon',
          label: '黎巴嫩',
          tag: 'Lebanon'
        }, {
          value: 'Seychelles',
          label: '塞舌尔',
          tag: 'Seychelles'
        }, {
          value: 'MOLDOVA, REPUBLIC OF',
          label: '摩尔多瓦',
          tag: 'MOLDOVA, REPUBLIC OF'
        }, {
          value: 'MONTENEGRO',
          label: '黑山',
          tag: 'MONTENEGRO'
        }, {
          value: 'MOROCCO',
          label: '摩洛哥',
          tag: 'MOROCCO'
        }, {
          value: 'MYANMAR',
          label: '缅甸',
          tag: 'MYANMAR'
        }, {
          value: 'NETHERLANDS, THE',
          label: '荷兰',
          tag: 'NETHERLANDS, THE'
        }, {
          value: 'NEW CALEDONIA',
          label: '新喀里多尼亚',
          tag: 'NEW CALEDONIA'
        }, {
          value: 'Syria',
          label: '叙利亚',
          tag: 'Syria'
        }, {
          value: 'Republic of Cape Verde',
          label: '佛得角共和国',
          tag: 'Republic of Cape Verde'
        }, {
          value: 'Mali',
          label: '马里共和国',
          tag: 'Mali'
        }, {
          value: 'NEW ZEALAND',
          label: '新西兰',
          tag: 'NEW ZEALAND'
        }, {
          value: 'Guadeloupe',
          label: '瓜德罗普',
          tag: 'Guadeloupe'
        }, {
          value: 'Mozambique',
          label: '莫桑比克',
          tag: 'Mozambique'
        }, {
          value: 'NIGERIA',
          label: '尼日利亚',
          tag: 'NIGERIA'
        }, {
          value: 'NORWAY',
          label: '挪威',
          tag: 'NORWAY'
        }, {
          value: 'PAKISTAN',
          label: '巴基斯坦',
          tag: 'PAKISTAN'
        }, {
          value: 'Panama',
          label: '巴拿马',
          tag: 'Panama'
        }, {
          value: 'ARMENIA',
          label: '亚美尼亚',
          tag: 'ARMENIA'
        }, {
          value: 'Ethiopia',
          label: '埃塞俄比亚',
          tag: 'Ethiopia'
        }, {
          value: 'PERU',
          label: '秘鲁',
          tag: 'PERU'
        }, {
          value: 'Benin',
          label: '贝宁',
          tag: 'Benin'
        }, {
          value: 'PHILIPPINES, THE',
          label: '菲律宾',
          tag: 'PHILIPPINES, THE'
        }, {
          value: 'POLAND',
          label: '波兰',
          tag: 'POLAND'
        }, {
          value: 'Iraq',
          label: '伊拉克',
          tag: 'Iraq'
        }, {
          value: 'PORTUGAL',
          label: '葡萄牙',
          tag: 'PORTUGAL'
        }, {
          value: 'PUERTO RICO',
          label: '波多黎各',
          tag: 'PUERTO RICO'
        }, {
          value: 'QATAR',
          label: '卡塔尔',
          tag: 'QATAR'
        }, {
          value: 'ROMANIA',
          label: '罗马尼亚',
          tag: 'ROMANIA'
        }, {
          value: 'SAUDI ARABIA',
          label: '沙特阿拉伯',
          tag: 'SAUDI ARABIA'
        }, {
          value: 'SENEGAL',
          label: '塞内加尔',
          tag: 'SENEGAL'
        }, {
          value: 'SERBIA, REPUBLIC OF',
          label: '塞尔维亚',
          tag: 'SERBIA, REPUBLIC OF'
        }, {
          value: 'SINGAPORE',
          label: '新加坡',
          tag: 'SINGAPORE'
        }, {
          value: 'SLOVENIA',
          label: '斯洛文尼亚',
          tag: 'SLOVENIA'
        }, {
          value: 'SOUTH AFRICA',
          label: '南非',
          tag: 'SOUTH AFRICA'
        }, {
          value: 'SPAIN',
          label: '西班牙',
          tag: 'SPAIN'
        }, {
          value: 'SRI LANKA',
          label: '斯里兰卡',
          tag: 'SRI LANKA'
        }, {
          value: 'SWEDEN',
          label: '瑞典',
          tag: 'SWEDEN'
        }, {
          value: 'Samoa',
          label: '萨摩亚',
          tag: 'Samoa'
        }, {
          value: 'SWITZERLAND',
          label: '瑞士联邦',
          tag: 'SWITZERLAND'
        }, {
          value: 'TAIWAN',
          label: '中国台湾',
          tag: 'TAIWAN'
        }, {
          value: 'TANZANIA',
          label: '坦桑尼亚',
          tag: 'TANZANIA'
        }, {
          value: 'TURKEY',
          label: '土耳其',
          tag: 'TURKEY'
        }, {
          value: 'THAILAND',
          label: '泰国',
          tag: 'TURKEY'
        }, {
          value: 'TURKS AND CAICOS ISLANDS',
          label: '特克斯与凯科斯群岛',
          tag: 'TURKS AND CAICOS ISLANDS'
        }, {
          value: 'UNITED ARAB EMIRATES',
          label: '阿拉伯联合酋长国',
          tag: 'UNITED ARAB EMIRATES'
        }, {
          value: 'UZBEKISTAN',
          label: '乌兹别克斯坦',
          tag: 'UZBEKISTAN'
        }, {
          value: 'VANUATU',
          label: '瓦努阿图',
          tag: 'VANUATU'
        }, {
          value: 'VENEZUELA',
          label: '委内瑞拉',
          tag: 'VENEZUELA'
        }, {
          value: 'VIETNAM',
          label: '越南',
          tag: 'VIETNAM'
        }, {
          value: 'ZAMBIA',
          label: '赞比亚',
          tag: 'ZAMBIA'
        }, {
          value: 'Ukraine',
          label: '乌克兰',
          tag: 'Ukraine'
        }, {
          value: 'Uruguay',
          label: '乌拉圭',
          tag: 'Uruguay'
        }, {
          value: 'Congo',
          label: '刚果共和国',
          tag: 'Congo'
        }, {
          value: 'Nicaragua',
          label: '尼加拉瓜',
          tag: 'Nicaragua'
        }, {
          value: 'Honduras',
          label: '洪都拉斯',
          tag: 'Honduras'
        }, {
          value: 'Uganda',
          label: '乌干达',
          tag: 'Uganda'
        }, {
          value: 'Argentina',
          label: '阿根廷',
          tag: 'Argentina'
        }],
        value_gj_sh: '',
        value_ct_sh: '',
        value_ct: '',
        value_gj: "",
        options_ct_sh: [{
          value: 'USA',
          label: '美国',
          tag: 'USA'
        }, {
          value: 'UK',
          label: '英国',
          tag: 'UK'
        }, {
          value: 'AUSTRALIA',
          label: '澳大利亚',
          tag: 'AUSTRALIA'
        }, {
          value: 'Azerbaijan',
          label: '阿塞拜疆',
          tag: 'Azerbaijan'
        }, {
          value: 'AUSTRIA',
          label: '奥地利',
          tag: 'AUSTRIA'
        }, {
          value: 'Algeria',
          label: '阿尔及利亚',
          tag: 'Algeria'
        }, {
          value: 'Albania',
          label: '阿尔巴尼亚',
          tag: 'Albania'
        }, {
          value: 'Angola',
          label: '安哥拉',
          tag: 'Angola'
        }, {
          value: 'Andorra',
          label: '安道尔',
          tag: 'Andorra'
        }, {
          value: 'BAHRAIN',
          label: '巴林',
          tag: 'BAHRAIN'
        }, {
          value: 'Botswana',
          label: '博茨瓦纳',
          tag: 'Botswana'
        }, {
          value: 'burkina faso ',
          label: '布基纳法索',
          tag: 'burkina faso '
        }, {
          value: 'BANGLADESH',
          label: '孟加拉国',
          tag: 'BANGLADESH'
        }, {
          value: 'BELGIUM',
          label: '比利时',
          tag: 'BELGIUM'
        }, {
          value: 'SAN MARINO',
          label: '圣马力诺',
          tag: 'SAN MARINO'
        }, {
          value: 'Oman',
          label: '阿曼',
          tag: 'Oman'
        }, {
          value: 'Maldives',
          label: '马尔代夫',
          tag: 'Maldives'
        }, {
          value: 'Malawi',
          label: '马拉维',
          tag: 'Malawi'
        }, {
          value: 'Slovakia',
          label: '斯洛伐克',
          tag: 'Slovakia'
        }, {
          value: 'Cameroon',
          label: '喀麦隆',
          tag: 'Cameroon'
        }, {
          value: 'BOLIVIA',
          label: '玻利维亚',
          tag: 'BOLIVIA'
        }, {
          value: 'BRAZIL',
          label: '巴西',
          tag: 'BRAZIL'
        }, {
          value: 'BRUNEI',
          label: '文莱',
          tag: 'BRUNEI'
        }, {
          value: 'BULGARIA',
          label: '保加利亚',
          tag: 'BULGARIA'
        }, {
          value: 'Bosnia',
          label: '波斯尼亚',
          tag: 'Bosnia'
        }, {
          value: 'Ghana',
          label: '加纳',
          tag: 'Ghana'
        }, {
          value: 'CANADA',
          label: '加拿大',
          tag: 'CANADA'
        }, {
          value: 'CAYMAN ISLANDS',
          label: '开曼群岛',
          tag: 'CAYMAN ISLANDS'
        }, {
          value: 'NORTH MACEDONIA',
          label: '马其顿',
          tag: 'NORTH MACEDONIA'
        }, {
          value: 'CHILE',
          label: '智利',
          tag: 'CHILE'
        }, {
          value: 'JERSEY',
          label: '泽西岛',
          tag: 'JERSEY'
        }, {
          value: 'zimbabwe',
          label: '津巴布韦',
          tag: 'zimbabwe'
        }, {
          value: 'curacao ',
          label: '库拉索',
          tag: 'curacao '
        }, {
          value: 'CHINA',
          label: '中国',
          tag: 'CHINA'
        }, {
          value: 'Colombia',
          label: '哥伦比亚',
          tag: 'Colombia'
        }, {
          value: 'Costa Rica',
          label: '哥斯达黎加',
          tag: 'Costa Rica'
        }, {
          value: 'EL SALVADOR',
          label: '萨尔瓦多',
          tag: 'EL SALVADOR'
        }, {
          value: 'COTE D IVOIRE',
          label: '科特迪瓦',
          tag: 'COTE D IVOIRE'
        }, {
          value: 'Kosovo',
          label: '科索沃',
          tag: 'Kosovo'
        }, {
          value: 'Ecuador',
          label: '厄瓜多尔',
          tag: 'Ecuador'
        }, {
          value: 'Yemen',
          label: '也门',
          tag: 'Yemen'
        }, {
          value: 'CROATIA',
          label: '克罗地亚',
          tag: 'CROATIA'
        }, {
          value: 'Rwanda',
          label: '卢旺达',
          tag: 'Rwanda'
        }, {
          value: 'CYPRUS',
          label: '塞浦路斯',
          tag: 'CYPRUS'
        }, {
          value: 'CZECH REPUBLIC, THE',
          label: '捷克共和国',
          tag: 'CZECH REPUBLIC, THE'
        }, {
          value: 'DENMARK',
          label: '丹麦',
          tag: 'DENMARK'
        }, {
          value: 'Reunion Island',
          label: '留尼汪岛',
          tag: 'Reunion Island'
        }, {
          value: 'DOMINICAN REPUBLIC',
          label: '多米尼加共和国',
          tag: 'DOMINICAN REPUBLIC'
        }, {
          value: 'Erdogua',
          label: '厄尔多瓜',
          tag: 'Erdogua'
        }, {
          value: 'Cambodia',
          label: '柬埔寨',
          tag: 'Cambodia'
        }, {
          value: 'Guinea',
          label: '几内亚',
          tag: 'Guinea'
        }, {
          value: 'EGYPT',
          label: '埃及',
          tag: 'EGYPT'
        }, {
          value: 'ESTONIA',
          label: '爱沙尼亚',
          tag: 'ESTONIA'
        }, {
          value: 'FINLAND',
          label: '芬兰',
          tag: 'FINLAND'
        }, {
          value: 'FRANCE',
          label: '法国',
          tag: 'FRANCE'
        }, {
          value: 'GERMANY',
          label: '德国',
          tag: 'GERMANY'
        }, {
          value: 'GREECE',
          label: '希腊',
          tag: 'GREECE'
        }, {
          value: 'GUAM',
          label: '关岛',
          tag: 'GUAM'
        }, {
          value: 'NAMIBIA',
          label: '纳米比亚',
          tag: 'NAMIBIA'
        }, {
          value: 'Georgia',
          label: '格鲁吉亚',
          tag: 'Georgia'
        }, {
          value: 'GUATEMALA',
          label: '危地马拉',
          tag: 'GUATEMALA'
        }, {
          value: 'HAITI',
          label: '海地',
          tag: 'HAITI'
        }, {
          value: 'HONG KONG SAR, CHINA',
          label: '中国香港特别行政区',
          tag: 'HONG KONG SAR, CHINA'
        }, {
          value: 'HUNGARY',
          label: '匈牙利',
          tag: 'HUNGARY'
        }, {
          value: 'ICELAND',
          label: '冰岛',
          tag: 'ICELAND'
        }, {
          value: 'Jamaica',
          label: '牙买加',
          tag: 'Jamaica'
        }, {
          value: 'INDIA',
          label: '印度',
          tag: 'INDIA'
        }, {
          value: 'INDONESIA',
          label: '印度尼西亚',
          tag: 'INDONESIA'
        }, {
          value: 'IRELAND, REPUBLIC OF',
          label: '爱尔兰共和国',
          tag: 'IRELAND, REPUBLIC OF'
        }, {
          value: 'MARTINIQUE',
          label: '马提尼克岛',
          tag: 'MARTINIQUE'
        }, {
          value: 'ISRAEL',
          label: '以色列',
          tag: 'ISRAEL'
        }, {
          value: 'ITALY',
          label: '意大利',
          tag: 'ITALY'
        }, {
          value: 'IRAN (ISLAMIC REPUBLIC OF)',
          label: '伊朗',
          tag: 'IRAN (ISLAMIC REPUBLIC OF)'
        }, {
          value: 'JAPAN',
          label: '日本',
          tag: 'JAPAN'
        }, {
          value: 'JORDAN',
          label: '约旦',
          tag: 'JORDAN'
        }, {
          value: 'KAZAKHSTAN',
          label: '哈萨克斯坦',
          tag: 'KAZAKHSTAN'
        }, {
          value: 'KENYA',
          label: '肯尼亚',
          tag: 'KENYA'
        }, {
          value: 'KOREA, REPUBLIC OF (SOUTH K.)',
          label: '韩国',
          tag: 'South Korea'
        }, {
          value: 'KUWAIT',
          label: '科威特',
          tag: 'KUWAIT'
        }, {
          value: 'LATVIA',
          label: '拉脱维亚',
          tag: 'LATVIA'
        }, {
          value: 'LITHUANIA',
          label: '立陶宛',
          tag: 'LITHUANIA'
        }, {
          value: 'Liberia',
          label: '利比里亚',
          tag: 'Liberia'
        }, {
          value: 'LUXEMBOURG',
          label: '卢森堡',
          tag: 'LUXEMBOURG'
        }, {
          value: 'MACAO SAR, CHINA',
          label: '中国澳门',
          tag: 'MACAO SAR, CHINA'
        }, {
          value: 'MALAYSIA',
          label: '马来西亚',
          tag: 'MALAYSIA'
        }, {
          value: 'MALTA',
          label: '马耳他',
          tag: 'MALTA'
        }, {
          value: 'MAURITIUS',
          label: '毛里求斯',
          tag: 'MAURITIUS'
        }, {
          value: 'MEXICO',
          label: '墨西哥',
          tag: 'MEXICO'
        }, {
          value: 'Lebanon',
          label: '黎巴嫩',
          tag: 'Lebanon'
        }, {
          value: 'Seychelles',
          label: '塞舌尔',
          tag: 'Seychelles'
        }, {
          value: 'MOLDOVA, REPUBLIC OF',
          label: '摩尔多瓦',
          tag: 'MOLDOVA, REPUBLIC OF'
        }, {
          value: 'MONTENEGRO',
          label: '黑山',
          tag: 'MONTENEGRO'
        }, {
          value: 'MOROCCO',
          label: '摩洛哥',
          tag: 'MOROCCO'
        }, {
          value: 'MYANMAR',
          label: '缅甸',
          tag: 'MYANMAR'
        }, {
          value: 'NETHERLANDS, THE',
          label: '荷兰',
          tag: 'NETHERLANDS, THE'
        }, {
          value: 'NEW CALEDONIA',
          label: '新喀里多尼亚',
          tag: 'NEW CALEDONIA'
        }, {
          value: 'Syria',
          label: '叙利亚',
          tag: 'Syria'
        }, {
          value: 'Cape Verde',
          label: '佛得角共和国',
          tag: 'Cape Verde'
        }, {
          value: 'Mali',
          label: '马里共和国',
          tag: 'Mali'
        }, {
          value: 'NEW ZEALAND',
          label: '新西兰',
          tag: 'NEW ZEALAND'
        }, {
          value: 'Guadeloupe',
          label: '瓜德罗普',
          tag: 'Guadeloupe'
        }, {
          value: 'Mozambique',
          label: '莫桑比克',
          tag: 'Mozambique'
        }, {
          value: 'NIGERIA',
          label: '尼日利亚',
          tag: 'NIGERIA'
        }, {
          value: 'NORWAY',
          label: '挪威',
          tag: 'NORWAY'
        }, {
          value: 'PAKISTAN',
          label: '巴基斯坦',
          tag: 'PAKISTAN'
        }, {
          value: 'Panama',
          label: '巴拿马',
          tag: 'Panama'
        }, {
          value: 'ARMENIA',
          label: '亚美尼亚',
          tag: 'ARMENIA'
        }, {
          value: 'PERU',
          label: '秘鲁',
          tag: 'PERU'
        }, {
          value: 'Benin',
          label: '贝宁',
          tag: 'Benin'
        }, {
          value: 'Ethiopia',
          label: '埃塞俄比亚',
          tag: 'Ethiopia'
        }, {
          value: 'PHILIPPINES, THE',
          label: '菲律宾',
          tag: 'PHILIPPINES, THE'
        }, {
          value: 'POLAND',
          label: '波兰',
          tag: 'POLAND'
        }, {
          value: 'Iraq',
          label: '伊拉克',
          tag: 'Iraq'
        }, {
          value: 'PORTUGAL',
          label: '葡萄牙',
          tag: 'PORTUGAL'
        }, {
          value: 'PUERTO RICO',
          label: '波多黎各',
          tag: 'PUERTO RICO'
        }, {
          value: 'QATAR',
          label: '卡塔尔',
          tag: 'QATAR'
        }, {
          value: 'ROMANIA',
          label: '罗马尼亚',
          tag: 'ROMANIA'
        }, {
          value: 'SAUDI ARABIA',
          label: '沙特阿拉伯',
          tag: 'SAUDI ARABIA'
        }, {
          value: 'SENEGAL',
          label: '塞内加尔',
          tag: 'SENEGAL'
        }, {
          value: 'SERBIA, REPUBLIC OF',
          label: '塞尔维亚',
          tag: 'SERBIA, REPUBLIC OF'
        }, {
          value: 'SINGAPORE',
          label: '新加坡',
          tag: 'SINGAPORE'
        }, {
          value: 'SLOVENIA',
          label: '斯洛文尼亚',
          tag: 'SLOVENIA'
        }, {
          value: 'SOUTH AFRICA',
          label: '南非',
          tag: 'SOUTH AFRICA'
        }, {
          value: 'SPAIN',
          label: '西班牙',
          tag: 'SPAIN'
        }, {
          value: 'SRI LANKA',
          label: '斯里兰卡',
          tag: 'SRI LANKA'
        }, {
          value: 'SWEDEN',
          label: '瑞典',
          tag: 'SWEDEN'
        }, {
          value: 'Samoa',
          label: '萨摩亚',
          tag: 'Samoa'
        }, {
          value: 'SWITZERLAND',
          label: '瑞士联邦',
          tag: 'SWITZERLAND'
        }, {
          value: 'TAIWAN',
          label: '中国台湾',
          tag: 'TAIWAN'
        }, {
          value: 'TANZANIA',
          label: '坦桑尼亚',
          tag: 'TANZANIA'
        }, {
          value: 'TURKEY',
          label: '土耳其',
          tag: 'TURKEY'
        }, {
          value: 'THAILAND',
          label: '泰国',
          tag: 'TURKEY'
        }, {
          value: 'TURKS AND CAICOS ISLANDS',
          label: '特克斯与凯科斯群岛',
          tag: 'TURKS AND CAICOS ISLANDS'
        }, {
          value: 'UNITED ARAB EMIRATES',
          label: '阿拉伯联合酋长国',
          tag: 'UNITED ARAB EMIRATES'
        }, {
          value: 'UZBEKISTAN',
          label: '乌兹别克斯坦',
          tag: 'UZBEKISTAN'
        }, {
          value: 'VANUATU',
          label: '瓦努阿图',
          tag: 'VANUATU'
        }, {
          value: 'VENEZUELA',
          label: '委内瑞拉',
          tag: 'VENEZUELA'
        }, {
          value: 'VIETNAM',
          label: '越南',
          tag: 'VIETNAM'
        }, {
          value: 'ZAMBIA',
          label: '赞比亚',
          tag: 'ZAMBIA'
        }, {
          value: 'Ukraine',
          label: '乌克兰',
          tag: 'Ukraine'
        }, {
          value: 'Uruguay',
          label: '乌拉圭',
          tag: 'Uruguay'
        }, {
          value: 'Congo',
          label: '刚果共和国',
          tag: 'Congo'
        }, {
          value: 'Nicaragua',
          label: '尼加拉瓜',
          tag: 'Nicaragua'
        }, {
          value: 'Honduras',
          label: '洪都拉斯',
          tag: 'Honduras'
        }, {
          value: 'Uganda',
          label: '乌干达',
          tag: 'Uganda'
        }, {
          value: 'Argentina',
          label: '阿根廷',
          tag: 'Argentina'
        }],
        plgm_gj: "批量购买",
        ct_plgm: "批量购买",
        dialog_ct_buy: false,
        dialog_gj_buy: false,
        form_reg: {
          user: "",
          pass: "",
          rpass: "",
          xm: "",
          phone: "",
          codes: "",
        },
        regFormRules: {
          // 验证用户名
          user: [{
              required: true,
              message: '用户名不能为空',
              tigger: 'blur'
            },
            {
              min: 8,
              message: '长度最少 8 个字符',
              trigger: 'blur'
            }, {
              pattern: '^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$',
              message: '请填写正确的邮箱'
            }
          ],
          // 验证密码
          pass: [{
              required: true,
              message: '密码不能为空',
              tigger: 'blur'
            },
            {
              min: 5,
              max: 15,
              message: '长度在 5 到 15 个字符',
              trigger: 'blur'
            }, {
              pattern: '^[0-9a-zA-Z_]{1,}$',
              message: '密码只能包含大小字母和数字,{a-z,A-z,0-9}'
            }
          ],
          rpass: [{
              required: true,
              message: '两次密码输入不一致',
              tigger: 'blur'
            },
            {
              min: 5,
              max: 15,
              message: '长度在 5 到 15 个字符',
              trigger: 'blur'
            },
            {
              validator: validatePass2,
              trigger: 'blur'
            }, {
              pattern: '^[0-9a-zA-Z_]{1,}$',
              message: '密码只能包含大小字母和数字,{a-z,A-z,0-9}'
            }
          ],
          phone: [{
            required: true,
            message: '手机号码不能为空',
            tigger: 'blur'
          }],
          xm: [{
            required: true,
            message: '姓名不能为空',
            tigger: 'blur'
          }],
          codes: [{
            required: true,
            message: '姓名不能为空',
            tigger: 'blur'
          }]
        },
        dialog_reg: false,
        form_login: {
          user: "",
          pass: ""
        }, // 表单验证规则
        loginFormRules: {
          // 验证用户名
          user: [{
            required: true,
            message: '用户名不能为空',
            tigger: 'blur'
          }],
          // 验证密码
          pass: [{
              required: true,
              message: '密码不能为空',
              tigger: 'blur'
            },
            {
              min: 5,
              max: 15,
              message: '长度在 5 到 15 个字符',
              trigger: 'blur'
            }, {
              pattern: '^[0-9a-zA-Z_]{1,}$',
              message: '密码只能包含大小字母和数字,{a-z,A-z,0-9}'
            }
          ]
        },
        dialog_login: false,
        my_data: [],
        form_cs: {
          startTime: "",
          endTime: "",
          fhd_city: "",
          fhd_gj: "",
          fhd_state: "",
          shd_city: "",
          shd_state: "",
          pagesize: "",
          // remark: ""
        },
        form_gj: {
          fhd_gj: "",
          shd_gj: "",
          pagesize: "",
          startTime: "",
          endTime: ""
        },
        currentPage: 1,
        pagesize: 50,
        total: 0,
        currentPage_ct: 1,
        total_ct: 0,
        pagesize_ct: 50,
        currentPage_my: 1,
        total_my: 0,
        ct_data: [],
        activeName: 'second',
        multipleSelection_coutry: [],
        multipleSelection_city: [],
        tableData: [],
        names: "",
        money: "",
        // 城市购买id
        ct_buy_id: "",
        // 城市批量购买id
        ct_buy_id_list: "",
        // 城市购买类型
        ct_buy_types: "",
        // 国家购买类型
        gj_buy_types: "",
        // 国家购买ID
        gj_buy_id: "",
        // 国家批量购买id
        gj_buy_id_list: ""
      }
    },
    mounted: function() {

    },
    methods: {
      // tab点击事件
      handleTabClick(tab, event) {
        if (tab.name == "third") {
          this.getCodes()
        } else if (tab.name == "second") {
          this.getCity('rf')
        } else if (tab.name == "myOrder") {
          this.getMyOrder()
        }
      },
      cz_tc() {
        const h = this.$createElement;
        this.$notify({
          title: '充值公告',
          message: '充值通道目前已关闭，预计3天之后修复使用，充值请找客服！',
          type: 'warning'
        });
      },
      // 格式化时间
      getTime( /** timestamp=0 **/ ) {
        const ts = arguments[0] || 0
        let t, y, m, d, h, i, s
        t = ts ? new Date(ts * 1000) : new Date()
        y = t.getFullYear()
        m = t.getMonth() + 1
        d = t.getDate()
        h = t.getHours()
        i = t.getMinutes()
        s = t.getSeconds()
        // 可依据须要在这里定义时间格式
        return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10

          ?
          '0' + d :
          d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
      },
      // 国家 多选事件 和 分页
      handleSelectionChange(val) {

        this.multipleSelection_coutry = val;
        this.gj_buy_id_list = ""
        if (this.multipleSelection_coutry.length > 0) {
          this.plgm_gj = "批量购买（已选" + this.multipleSelection_coutry.length + "个）"
        } else {
          this.plgm_gj = "批量购买"
        }
        for (var i = 0; i < val.length; i++) {
          if (i + 1 == val.length || val.length == "1") {
            this.gj_buy_id_list = this.gj_buy_id_list + val[i].id
          } else {
            this.gj_buy_id_list = this.gj_buy_id_list + val[i].id + ","
          }
        }
      },
      handleSizeChange(val) {
        this.currentPage = 1
        this.pageSize = val
        this.getCoutry('rf')
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getCoutry('rf')
      },
      // 城市 多选事件 和 分页
      handleSelectionChange_ct(val) {
        this.multipleSelection_city = val;
        this.ct_buy_id_list = ""
        if (this.multipleSelection_city.length > 0) {
          this.ct_plgm = "批量购买（已选" + this.multipleSelection_city.length + "个）"
        } else {
          this.ct_plgm = "批量购买"
        }
        for (var i = 0; i < val.length; i++) {
          if (i + 1 == val.length || val.length == "1") {
            this.ct_buy_id_list = this.ct_buy_id_list + val[i].id
          } else {
            this.ct_buy_id_list = this.ct_buy_id_list + val[i].id + ","
          }
        }
      },
      handleSizeChange_ct(val) {
        this.currentPage_ct = 1
        this.pagesize_ct = val
        this.getCity('rf')
      },
      handleCurrentChange_ct(val) {
        this.currentPage_ct = val
        this.getCity('rf')
      },
      // 我的单号分页和多选
      handleSelectionChange_my(val) {
        this.multipleSelection = val;
      },
      handleSizeChange_my(val) {},
      handleCurrentChange_my(val) {
        this.currentPage_my = val
        this.getMyOrder()
      },
      // 国家购买单号
      gj_buy() {
        if (this.isLogin == false) {
          this.dialog_gj_buy = false
          this.dialog_login = true
          return
        }
        const loading = this.$loading({
          lock: true,
          text: '购买中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let params = {
          id: this.gj_buy_id,
          source: "country"
        }

        const result = this.$http.post('/waybill/buy', params, {
          headers: {
            token: this.$cookie.get("token")
          }
        })
        result.then((res) => {
          if (res.data.code == "1") {
            if (res.data.data.waybill_sn.length > 0) {
              this.order_list = res.data.data.waybill_sn[0]
              this.dialog_gj_buy = false
              this.dialog_buy_cg = true
              this.money = res.data.data.log.after
              this.buy_times = this.getTime(res.data.data.log.createtime)
              this.buy_money = res.data.data.log.money
              this.buy_order_sn = res.data.data.log.order_sn
              this.buy_sums = res.data.data.waybill_sn.length
              loading.close()
              this.getCodes()
            } else {
              this.$message.error(res.data.msg)
              loading.close()
            }
          } else {
            this.$message.error(res.data.msg)
            loading.close()
          }
        }, (error) => {
          this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
          loading.close()
        })
      },
      // 邮编批量购买传递值
      batch_buy_gj() {
        this.order_list = ""
        if (this.multipleSelection_coutry.length > 0) {
          this.gj_buy_id = ""
          this.dialog_gj_buy = true
          this.gj_buy_types = "batch"
        } else {
          this.$message.error("请选择要购买的单号！")
        }
      },
      // 城市购买单号传递值
      dialogct_buy(row) {
        this.dialog_ct_buy = true
        this.ct_buy_id = row.id
        this.ct_buy_id_list = ""
        this.ct_buy_types = "dan"
        this.order_list = ""
      },
      // 城市购买单号
      ct_buy(row) {
        if (this.isLogin == false) {
          this.dialog_ct_buy = false
          this.dialog_login = true
          return
        }
        const loading = this.$loading({
          lock: true,
          text: '购买中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let params = {
          id: this.ct_buy_id,
          source: "city"
        }

        const result = this.$http.post('/waybill/buy', params, {
          headers: {
            token: this.$cookie.get("token")
          }
        })
        result.then((res) => {
          if (res.data.code == "1") {
            if (res.data.data.waybill_sn.length > 0) {
              this.order_list = res.data.data.waybill_sn[0]
              this.dialog_ct_buy = false
              this.dialog_buy_cg = true
              this.money = res.data.data.log.after
              this.buy_times = this.getTime(res.data.data.log.createtime)
              this.buy_money = res.data.data.log.money
              this.buy_order_sn = res.data.data.log.order_sn
              this.buy_sums = res.data.data.waybill_sn.length
              loading.close()
              this.getCity('rf')
            } else {
              this.$message.error(res.data.msg)
              loading.close()
            }
          } else {
            this.$message.error(res.data.msg)
            loading.close()
          }
        }, (error) => {
          this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
          loading.close()
        })
      },
      // 城市批量购买传递值
      batch_buy_ct() {
        this.order_list = ""
        if (this.multipleSelection_city.length > 0) {
          this.ct_buy_id = ""
          this.dialog_ct_buy = true
          this.ct_buy_types = "batch"
        } else {
          this.$message.error("请选择要购买的单号！")
        }
      },
      // 邮编批量购买
      gj_buy_batch() {
        var pp = 0
        var sc = 0
        var codesl = ""
        var codesList = []
        if (this.codes_form.codes.replace(/\n/g, ',').charAt(this.codes_form.codes.length - 1) == ",") {
          codesl = this.codes_form.codes.replace(/\n/g, ',')
          codesl = codesl.slice(0, -1);
        } else {
          codesl = this.codes_form.codes.replace(/\n/g, ',')
        }
        codesList = codesl.split(",")

        if (this.isLogin == false) {
          this.dialog_ct_buy = false
          this.dialog_login = true
          return
        }
        const loading = this.$loading({
          lock: true,
          text: '购买中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let params = {
          ids: this.gj_buy_id_list,
          source: "country"
        }

        const result = this.$http.post('/waybill/batchBuy', params, {
          headers: {
            token: this.$cookie.get("token")
          }
        })
        result.then((res) => {
          if (res.data.code == "1") {
            if (res.data.data.waybill_sn.length > 0) {
              if (codesList.length > 1) {
                for (var i = 0; i < codesList.length; i++) {
                  if (codesList[i].charAt(0) == "0") {
                    codesList[i] = codesList[i].slice(1, 5)
                  }
                  for (var k = 0; k < res.data.data.waybill_sn.length; k++) {

                    if (res.data.data.waybill_sn[k].includes(codesList[i]) && this.order_list.indexOf(codesList[
                        i]) === -1) {
                      this.order_list = this.order_list + res.data.data.waybill_sn[k] + "\n"
                      pp = 1
                      sc = 1
                    } else {
                      pp = 0
                    }
                  }
                  if (pp == 0 && sc == 0) {
                    this.order_list = this.order_list + codesList[i] + " - 未匹配到" + "\n"
                  }
                  sc = 0
                }
              } else {
                for (var i = 0; i < res.data.data.waybill_sn.length; i++) {
                  this.order_list = this.order_list + res.data.data.waybill_sn[i] + "\n"
                }
              }


              this.dialog_gj_buy = false
              this.dialog_buy_cg = true
              this.money = res.data.data.log.after
              this.buy_times = this.getTime(res.data.data.log.createtime)
              this.buy_money = res.data.data.log.money
              this.buy_order_sn = res.data.data.log.order_sn
              this.buy_sums = res.data.data.waybill_sn.length
              loading.close()
              this.getCodes()
            } else {
              this.$message.error(res.data.msg)
              loading.close()
            }
          } else {
            this.$message.error(res.data.msg)
            loading.close()
          }
        }, (error) => {
          this.$message.error(res.data.msg)
          loading.close()
        })
      },
      // 城市批量购买
      ct_buy_batch() {
        if (this.isLogin == false) {
          this.dialog_ct_buy = false
          this.dialog_login = true
          return
        }
        const loading = this.$loading({
          lock: true,
          text: '购买中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let params = {
          ids: this.ct_buy_id_list,
          source: "city"
        }

        const result = this.$http.post('/waybill/batchBuy', params, {
          headers: {
            token: this.$cookie.get("token")
          }
        })
        result.then((res) => {
          if (res.data.code == "1") {
            if (res.data.data.waybill_sn.length > 0) {
              for (var i = 0; i < res.data.data.waybill_sn.length; i++) {
                this.order_list = this.order_list + res.data.data.waybill_sn[i] + "\n"
              }
              this.dialog_ct_buy = false
              this.dialog_buy_cg = true
              this.money = res.data.data.log.after
              this.buy_times = this.getTime(res.data.data.log.createtime)
              this.buy_money = res.data.data.log.money
              this.buy_order_sn = res.data.data.log.order_sn
              this.buy_sums = res.data.data.waybill_sn.length
              loading.close()
              this.getCity('rf')
            } else {
              this.$message.error(res.data.msg)
              loading.close()
            }
          } else {
            this.$message.error(res.data.msg)
            loading.close()
          }
        }, (error) => {
          this.$message.error(res.data.msg)
          loading.close()
        })
      },
      // 邮编清空
      clear_cd() {
        this.codes_form.adr = ""
        // this.value_status = ""
        // this.codes_form.startTime = ""
        // this.codes_form.endTime = ""
        this.codes_form.codes = ""
      },
      // 城市清空
      claer_ct() {
        this.value_ct = ""
        this.value_ct_sh = ""
        this.form_cs.fhd_state = ""
        this.form_cs.fhd_city = ""
        // this.form_cs.remark = ""
        // this.form_cs.pagesize = ""
        this.form_cs.shd_state = ""
        this.form_cs.shd_city = ""
        // this.form_cs.startTime = ""
        // this.form_cs.endTime = ""
        // this.value_cs_lx = ""
      },
      // 国家清空
      claer_gj() {
        this.form_gj.fhd_gj = ""
        this.form_gj.shd_gj = ""
        this.form_gj.pagesize = ""
        this.form_gj.startTime = ""
        this.form_gj.endTime = ""
        this.value_gj = ""
        this.value_gj_sh = ""
        this.value_gj_lx = ""
        this.getCoutry()
      },
      // 收藏本站
      addFavorite() {
        var url = window.location;
        var title = document.title;
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf("360se") > -1) {
          alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
        } else if (ua.indexOf("msie 8") > -1) {
          window.external.AddToFavoritesBar(url, title); //IE8
        } else if (document.all) {
          try {
            window.external.addFavorite(url, title);
          } catch (e) {
            alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
          }
        } else if (window.sidebar) {
          window.sidebar.addPanel(title, url, "");
        } else {
          alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
        }

      },
      // 登出
      loginOut() {
        alert("退出账户！")
        this.isLogin = false
        this.$cookie.set('token', '')
        this.name = ""
        this.money = ""
      },
      // 复制单号
      copy() {
        // 创建一个临时的textarea元素
        var tempTextarea = document.createElement('textarea');
        tempTextarea.value = this.order_list;
        document.body.appendChild(tempTextarea);
        // 选中文本并执行复制命令
        tempTextarea.select();
        document.execCommand('copy');
        // 删除临时的textarea元素
        document.body.removeChild(tempTextarea);

        document.execCommand('copy')
        this.$message({
          type: 'success',
          message: '复制成功!'
        })
      },
      // 注册
      reg() {
        let reg = {
          email: this.form_reg.user,
          password: this.form_reg.pass,
          username: this.form_reg.xm,
          mobile: this.form_reg.phone,
          captcha: this.form_reg.codes,
          captcha_id: this.timmer
        }
        this.$refs.form_regFormRef.validate(valid => {
          if (!valid) return this.$message.error('注册失败，请重新注册!')
          const result = this.$http.post("/user/register", reg)
          result.then((res) => {
            if (res.data.code === 1) {
              this.$message({
                  message: '注册成功!',
                  type: 'success'
                }), this.$cookie.set('token', res.data.data.userinfo.token),
                this.dialog_reg = false,
                this.isLogin = true,
                this.names = res.data.data.userinfo.nickname,
                this.money = res.data.data.userinfo.money
            } else {
              this.$message.error(res.data.msg)
              this.getCodesy()
            }
          }, (error) => {
            this.$message.error('注册失败，请重新注册!')
            this.getCodesy()
          })
        })
      },
      // 获取验证码
      getCodesy() {
        this.timmer = Math.floor(Math.random() * (100000 - 10000) + 10000000)
        //this.coadeId = "https://api.dhldanhao.com/index?s=captcha/" + this.timmer
        this.coadeId = "https://api.byqgwcgzs.cn/index?s=captcha/" + this.timmer
      },
      //登录
      login() {
        let logins = {
          account: this.form_login.user,
          password: this.form_login.pass,
        }
        this.$refs.form_loginFormRef.validate(async valid => {
          if (!valid) return this.$message.error('登录错误，请重新登录!')
          const result = this.$http.post("/user/login", logins)
          result.then((res) => {
            if (res.data.code === 1) {
              this.$message({
                  message: '登录成功!',
                  type: 'success'
                }), this.$cookie.set('token', res.data.data.userinfo.token),
                this.getCity('rf'),
                this.dialog_login = false,
                this.isLogin = true,
                this.names = res.data.data.userinfo.nickname,
                this.money = res.data.data.userinfo.money,
                this.token = res.data.data.userinfo.token
            } else {
              this.$message.error(res.data.msg)
            }
          }, (error) => {
            this.$message.error(res.data.msg)
          })
        })
      },
      // 获取邮编列表
      getCodes(sc, pages) {

        this.token = this.$cookie.get('token')
        if (this.token != undefined && this.token != "") {

        } else {

          return this.$message.warning('请登录账号查询，购买单号!'), this.dialog_login = true
        }
        if (this.codes_form.startTime == null) {
          return this.$message.error('请选择上网时间!')
        }
        var codesl = ""
        if (this.codes_form.codes.replace(/\n/g, ',').charAt(this.codes_form.codes.length - 1) == ",") {
          codesl = this.codes_form.codes.replace(/\n/g, ',')
          codesl = codesl.slice(0, -1);
        } else {
          codesl = this.codes_form.codes.replace(/\n/g, ',')
        }
        if (this.form_gj.pagesize > 50) {
          return this.$message.error('最大查询数量为500，请勿超过500!'), loading.close();
        }
        const loading = this.$loading({
          lock: true,
          text: '查询中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let params = {}

        if (this.codes_form.startTime != null && this.codes_form.startTime != null) {
          this.codes_form.startTime = this.codes_form.startTime.toString().substring(0, 10)
        }
        if (this.codes_form.endTime != null && this.codes_form.endTime != null) {
          this.codes_form.endTime = this.codes_form.endTime.toString().substring(0, 10)
        }
        if (this.form_gj.pagesize == "") {
          this.form_gj.pagesize = 50
        }
        params = {
          page_size: "",
          begin_time: this.codes_form.startTime,
          end_time: this.codes_form.endTime,
          types: this.codes_types,
          codes: codesl,
          sn_status: this.value_status,
          start_address: this.codes_form.adr
        }
        // 将 params 对象转换为查询字符串
        let queryString = Object.keys(params)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');

        // 使用 GET 请求
        //const result = this.$http.get(`/waybill/listForCodes?${queryString}`);
        const result = this.$http.post('/waybill/listForCodes', params)
        result.then((res) => {
          if (res.data.code == "1") {
            this.tableData = res.data.data.data
            this.total = res.data.data.total
            for (let i = 0; i < this.tableData.length; i++) {
              this.tableData[i].createtime = this.getTime(res.data.data.data[i].createtime)
              this.tableData[i].online_time = this.getTime(res.data.data.data[i].online_time)
            }
            loading.close()
            if (this.codes_form.startTime != "" && this.codes_form.startTime != null) {

              this.codes_form.startTime = this.codes_form.startTime.toString() + "000"
            }
            if (this.codes_form.endTime != "" && this.codes_form.endTime != null) {
              this.codes_form.endTime = this.codes_form.endTime.toString() + "000"
            }
          } else {
            this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
            loading.close()
          }
        }, (error) => {
          this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
          loading.close()
        })
      },
      // 获取城市列表
      getCity(sc, pages) {
        this.token = this.$cookie.get('token')
        if (this.token != undefined && this.token != "") {

        } else {

          return this.$message.warning('请登录账号查询，购买单号!'), this.dialog_login = true
        }
        if (this.form_cs.pagesize > 500) {
          return this.$message.error('最大查询数量为500，请勿超过500!'), loading.close();
        }
        if (pages == "pages") {
          this.currentPage_ct = 1
        }
        let params = {}
        if (sc == "rf") {
          if (this.form_cs.pagesize <= 0) {
            this.form_cs.pagesize = 50
          }
          if (this.form_cs.startTime != "" && this.form_cs.startTime != null) {
            this.form_cs.startTime = this.form_cs.startTime.toString().substring(0, 10)
          }
          if (this.form_cs.startTime == null) {
            return this.$message.error('请选择上网时间!')

          }
          if (this.form_cs.endTime != "" && this.form_cs.endTime != null) {
            this.form_cs.endTime = this.form_cs.endTime.toString().substring(0, 10)
          }
          if (this.value_cs_lx == "EMS") {
            this.value_ct = "CHINA"
          } else if (this.value_cs_lx == "EUB") {
            this.value_ct = "CHINA"
          } else if (this.value_cs_lx == "USPS") {
            this.value_ct = "USA"
            this.value_ct_sh = "USA"
          }
          if(this.value_ct.includes("CHINA") || this.value_cs_lx == "USPS"){
            params = {
              page: this.currentPage_ct,
              page_size: this.form_cs.pagesize,
              begin_time: this.form_cs.startTime,
              end_time: this.form_cs.endTime,
              start_country: this.value_ct,
              start_state: this.form_cs.fhd_state,
              start_city: this.form_cs.fhd_city,
              end_country: this.value_ct_sh,
              end_state: this.form_cs.shd_state,
              end_city: this.form_cs.shd_city,
              // remark: this.form_cs.remark,
              types: this.value_cs_lx,
              sn_status: this.value_status
            }
          }else{
            params = {
              page: this.currentPage_ct,
              page_size: this.form_cs.pagesize,
              begin_time: this.form_cs.startTime,
              end_time: this.form_cs.endTime,
              start_country: "- "+this.value_ct,
              start_state: this.form_cs.fhd_state,
              start_city: this.form_cs.fhd_city,
              end_country: this.value_ct_sh,
              end_state: this.form_cs.shd_state,
              end_city: this.form_cs.shd_city,
              // remark: this.form_cs.remark,
              types: this.value_cs_lx,
              sn_status: this.value_status
            }
          }

        } else {
          params = {
            page: this.currentPage_ct,
            page_size: this.pagesize_ct,
            begin_time: "",
            end_time: "",
            start_country: "CHINA",
            start_state: "",
            start_city: "",
            end_country: "",
            end_state: "",
            end_city: "",
            // remark: "",
            types: "",
            value_status: ""
          }
        }
        const loading = this.$loading({
          lock: true,
          text: '查询中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let queryString = Object.keys(params)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');

        const result = this.$http.get(`/waybill/listForCity?${queryString}`)
        // const result = this.$http.post('/waybill/listForCity', params)
        result.then((res) => {
          if (res.data.code == "1") {
            this.ct_data = res.data.data.data
            this.total_ct = res.data.data.total
            for (let i = 0; i < this.ct_data.length; i++) {
              this.ct_data[i].createtime = this.getTime(res.data.data.data[i].createtime)
              this.ct_data[i].online_time = this.getTime(res.data.data.data[i].online_time)
            }
            loading.close()
            if (this.form_cs.startTime != "" && this.form_cs.startTime != null) {
              this.form_cs.startTime = this.form_cs.startTime.toString() + "000"
            }
            if (this.form_cs.endTime != "" && this.form_cs.endTime != null) {
              this.form_cs.endTime = this.form_cs.endTime.toString() + "000"
            }
          } else {
            this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
            loading.close()
          }
        }, (error) => {
          this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
          loading.close()
        })
      },
      // 已购单号列表
      getMyOrder() {
        // this.form_gj.startTime = ""
        // this.form_gj.endTime = ""
        // this.form_cs.startTime = ""
        // this.form_cs.endTime = ""
        const loading = this.$loading({
          lock: true,
          text: '查询中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let params = {
          page: this.currentPage_my,
          page_size: "50"
        }
        const result = this.$http.post('/waybill/listForMe', params, {
          headers: {
            token: this.token
          }
        })
        result.then((res) => {
          if (res.data.code == "1") {
            this.my_data = res.data.data.data
            this.total_my = res.data.data.total
            for (let i = 0; i < this.my_data.length; i++) {
              this.my_data[i].createtime = this.getTime(res.data.data.data[i].createtime)
              this.my_data[i].online_time = this.getTime(res.data.data.data[i].online_time)
              this.my_data[i].updatetime = this.getTime(res.data.data.data[i].updatetime)
            }
            loading.close()
          } else {
            this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
            loading.close()
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const day = now.getDate().toString().padStart(2, '0');
            var io = `${year}-${month}-${day} 00:00:00`
            const timestamp = new Date(io).getTime();
            this.form_cs.startTime = timestamp
            this.codes_form.startTime = timestamp
          }
        }, (error) => {
          this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
          loading.close()
          const now = new Date();
          const year = now.getFullYear();
          const month = (now.getMonth() + 1).toString().padStart(2, '0');
          const day = now.getDate().toString().padStart(2, '0');
          var io = `${year}-${month}-${day} 00:00:00`
          const timestamp = new Date(io).getTime();
          this.form_cs.startTime = timestamp
          this.codes_form.startTime = timestamp
        })
      },

      // cookie登录
      getCk() {
        this.token = this.$cookie.get('token')
        if (this.token != undefined && this.token != "") {
          const result = this.$http.post("/user/info", null, {
            headers: {
              token: this.token
            }
          })
          result.then((res) => {
            if (res.data.code === 1) {
              this.isLogin = true,
                this.names = res.data.data.userinfo.nickname,
                this.money = res.data.data.userinfo.money
              this.getCity('rf')
            } else {
              this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
            }
          }, (error) => {
            this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
          })
        } else {
          this.$message.warning('请登录账号查询，购买单号!')
          this.dialog_login = true
        }
      },
      clstartTime() {
        this.form_gj.startTime = ""
      },
      // 充值
      cz() {
        this.dialog_ewm = true
        this.qrcode = null
        let params = {
          price: this.je_1,
          type: 1
        }
        const result = this.$http.post("/recharge/recharge", params, {
          headers: {
            token: this.$cookie.get('token')
          }
        })
        result.then((res) => {
          if (res.data.code === 1) {
            this.qrcode = new QRCode(this.$refs.qrcodejs2, {
              text: res.data.data.payUrl,
              width: 250,
              height: 250,
              colorDark: '#000',
              colorLight: '#fff',
              correctLevel: QRCode.CorrectLevel.H
            })
            this.reallyPrice = res.data.data.reallyPrice
            this.payId = res.data.data.payId
            this.dates = this.getTime(res.data.data.data)
            this.price = res.data.data.price
            if (res.data.data.state == "0") {
              this.state = "等待支付"
            }
            orderid = res.data.data.orderId
          } else {
            this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
          }
          check()
        }, (error) => {
          this.$message.error('数据获取失败，请尝试刷新网页或更换浏览器!')
          this.dialog_ewm = false
        })
      },
      // 清空二维码
      closeTime() {
        $(".qr-code")[0].innerHTML = ""
        clearTimeout(this.timers)
      }
    },
    created() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      var io = `${year}-${month}-${day} 00:00:00`
      const timestamp = new Date(io).getTime();
      this.form_cs.startTime = timestamp
      this.codes_form.startTime = timestamp
      this.getCk()
      this.timmer = Math.floor(Math.random() * (100000 - 10000) + 10000000);
      //this.coadeId = "https://api.dhldanhao.com/index?s=captcha/" + this.timmer;
      this.coadeId = "https://api.byqgwcgzs.cn/index?s=captcha/" + this.timmer;




    },
    mounted: function() {
      var div_1 = document.getElementById("gzh");
      //给div_1绑定事件:onmouseover:鼠标移入事件
      div_1.onmouseover = function() {
        //将p标签显示出来，故需要将display的值设置为block
        document.getElementById("gzh-item").style.display = "block";
        document.getElementById("gzh-item").style.opacity = "1";
      }
      div_1.onmouseout = function() {
        document.getElementById("gzh-item").style.display = "none";
        document.getElementById("gzh-item").style.opacity = "0";
      }


      var div_3 = document.getElementById("qq_1");
      //给div_1绑定事件:onmouseover:鼠标移入事件
      div_3.onmouseover = function() {
        //将p标签显示出来，故需要将display的值设置为block
        document.getElementById("qq-1-item").style.display = "block";
        document.getElementById("qq-1-item").style.opacity = "1";
      }
      div_3.onmouseout = function() {
        document.getElementById("qq-1-item").style.display = "none";
        document.getElementById("qq-1-item").style.opacity = "0";
      }
    },

  }

  async function check() {
    var i = 0;
    //$.post("https://pay.dhldanhao.com/checkOrder", "orderId=" + orderid, function(data) {
    $.post("https://pay.byqgwcgzs.cn/checkOrder", "orderId=" + orderid, function(data) {
      if (data.code == 1) {
        Message({
          message: '恭喜您，充值成功！5秒后刷新页面!',
          type: 'success',
          duration: 6000
        });
        clearTimeout(this.timers)
        setTimeout(function() {
          location.reload()
        }, 5000);
      } else {
        i = i + 1
        if (data.msg == "订单已过期") {
          Message({
            message: '订单超时，请重新发起支付！',
            type: 'error',
            duration: 5000
          });
          clearTimeout(this.timers)
        } else {
          this.timers = window.setTimeout(function() {
            check()
          }, 1500);
        }
      }
    })
  }
</script>

<style>
  .qr-code {
    width: 250px;
    height: 250px;
    margin: 0 auto;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .zsts {
    position: absolute;
    background-color: #66B1FF;
    color: #ffff00;
    top: -30px;
    padding: 0 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 30px;
    line-height: 30px;
    font-weight: 600;
    z-index: 998;
  }

  .rdzt {
    font-size: 16px;
  }

  .zh_form .el-input__inner {
    height: 55px !important;
    font-size: 15px !important;
  }


  .order-info {
    width: 100%;
    height: 75px;
    overflow-wrap: break-word;
    padding: 5px;
    overflow: auto;
    resize: none !important;
    border: 1px solid #797979;
    -webkit-box-shadow: 0 4px 10px 0 rgba(135, 142, 154, .07);
    box-shadow: 0 4px 10px 0 rgba(135, 142, 154, .07);
    border-radius: 4px;
    cursor: default;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    outline: 0;
    color: #616161;
  }

  textarea:disabled {
    cursor: default;
    background-color: -internal-light-dark(rgba(239, 239, 239, 0.5), rgba(86, 86, 86, 0.5));
    color: -internal-light-dark(rgb(84, 84, 84), rgb(126, 126, 126));
    border-color: rgba(100, 100, 100, 0.5);
  }

  p {
    padding: 0;
    margin: 0;
  }

  .el-dialog {
    border-radius: 10px !important;
  }

  .buy_cg {
    position: absolute;
    width: 100px;
    left: 0;
    right: 0;
    margin: auto;
    top: -50px;
    border: 2px solid #ffffff;
    border-radius: 70px;
    background-color: #ffffff;
  }

  .xts {
    position: absolute;
    background-color: #66B1FF;
    color: #ffffff;
    top: 2px;
    padding: 5px 47px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 30px;
    line-height: 30px;
    font-weight: 600;
    z-index: 998;
    font-size: 16px;
  }

  .tb_img {
    position: absolute;
    width: 55px;
    left: 10px;
    top: -18px;
    z-index: 999;
  }
</style>
<style scoped>
  .el-radio.is-bordered {
    padding: 0;
    text-align: center;
    width: 140px;
    line-height: 45px;
    height: 45px;
    margin-top: 20px;
  }

  .el-radio img {
    position: relative;
    top: 4px;
    margin-right: 6px;
    padding-left: 10px;
  }
</style>
